<template>
    <div class="card shadow-sm mb-2" @mouseover="hover = true" @mouseleave="hover = false" :class="{ shadow: hover }"
        @click="onClickItem(item)">

        <div class="card-body p-1" :class="!item.isViewed ? 'unread-color' : ''">
            <div class="form-row">
                <div class="col-md-2 pt-3 pl-3">
                    <img :src="iconRenderer(item.type)" alt width="45" />
                </div>
                <div class="col-md-10 pt-2">
                    <div class="row chat-header">
                        <div class="col-md-12 text-left">
                            <h6>{{ item.title }}</h6>
                        </div>
                    </div>
                    <div v-if="!disposalRequestId && !repairTaskId" class="row chat-body">
                        <div class="col-md-12">{{ item.content }}</div>
                    </div>
                    <div v-else-if="isIncidentReport" class="row chat-body">
                        <div class="col-md-12">
                            {{ incidentReportContent }}
                            <b-link @click="redirectDisposalRequest" class="pl-0">
                                {{ item.meta.disposalRequestId }}
                            </b-link>
                        </div>
                    </div>
                    <div v-else-if="disposalRequestId" class="row chat-body">
                        <div class="col-md-12">
                            <b-link @click="redirectDisposalRequest" class="pl-0">
                                {{ item.meta.disposalRequestId }}
                            </b-link>
                            {{ item.content }}
                        </div>
                    </div>
                    <div v-else-if="repairTaskId" class="row chat-body">
                        <div class="col-md-12">
                            <b-link @click="redirectRepairRequest" class="pl-0">
                                {{ item.meta.repairTaskId }}
                            </b-link>
                            {{ item.content }}
                        </div>
                    </div>
                    <div v-if="dispatchId" class="row chat-body">
                        <div class="col-md-12">
                            <b-link @click="openDispatchDetails(item)" class="pl-0">
                                See Details of {{ dispatchId }}
                            </b-link>
                        </div>
                    </div>
                    <div class="row chat-footer">
                        <div class="col-md-12 text-right" style="color: #999b9e">
                            <time-ago :refresh="60" :datetime="dateSent" locale="en" :tooltip="true"
                                :style="{ 'font-size': '8px' }" long />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
// Component
import dispatch from '@/assets/images/notifications/dispatch.svg';
import inTransit from '@/assets/images/notifications/in-transit.svg';
import receive from '@/assets/images/notifications/receive.svg';
import welcome from '@/assets/images/notifications/welcome.svg';
import locked from '@/assets/images/notifications/locked.svg';
import changeLocation from '@/assets/images/notifications/change-location.svg';
import approve from '@/assets/images/notifications/approve.svg';
import nbvReview from '@/assets/images/notifications/nbv-review.svg';
import document from '@/assets/images/notifications/document.svg';
import onGoing from '@/assets/images/notifications/on-going.svg';
import reject from '@/assets/images/notifications/reject.svg';
import done from '@/assets/images/notifications/done.svg';

// DAO
import dispatchDAO from '@/database/dispatches';
import notificationDAO from '@/database/notifications';

// Util
import { DateUtil } from '@/utils/dateutil';

// Others
import config from '@/config/env-constants';
import EventBus from '@/shared/event-bus';
import TimeAgo from 'vue2-timeago';


export default {
    name: 'NotificationItem',
    components: { TimeAgo },
    data() {
        return {
            hover: false,
            icons: {
                dispatch,
                inTransit,
                receive,
                welcome,
                locked,
                changeLocation,
                approve,
                nbvReview,
                document,
                onGoing,
                reject,
                done
            },
        };
    },
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    computed: {
        dateSent() {
            return DateUtil.getFormattedDateWithTime(this.item.dateSent);
        },
        dispatchId() {
            const item = this.item ? this.item : {};
            return item.meta && item.meta.dispatchId ? item.meta.dispatchId : null;
        },
        disposalRequestId() {
            const item = this.item ? this.item : {};
            return item.meta && item.meta.disposalRequestId ? item.meta.disposalRequestId : null;
        },
        disposalUrl() {
            const url = this.$store.getters.isSuperAdmin ? '/admin/admin-disposal' : '/disposal';
            return `${url}/${this.disposalRequestId}`;
        },
        repairTaskId() {
            const item = this.item ? this.item : {};
            return item.meta && item.meta.repairTaskId ? item.meta.repairTaskId : null;
        },
        repairUrl() {
            const url = this.$store.getters.isSuperAdmin ? '/admin/admin-repair' : '/repair';
            return `${url}/${this.repairTaskId}`;
        },
        isIncidentReport() {
            const item = this.item ? this.item : {};
            return item.type === config.notificationType.INCIDENT_REPORT_SUBMITTED;
        },
        incidentReportContent() {
            const item = this.item ? this.item : {};
            return item.content.replace(this.disposalRequestId, "");
        }
    },
    methods: {
        async onClickItem(item) {
            try {
                if (item && !item.isViewed) {
                    await notificationDAO.setAsViewed(item.id);
                }
            } catch (error) {
                this.$toaster.error('Error loading data. Please reload the page again.');
            }
        },
        iconRenderer(type) {
            let icon;
            if (type === config.notificationType.NEW_COMPANY) {
                icon = this.icons.welcome;
            } else if (type === config.notificationType.DISPATCH_DEPLOYED) {
                icon = this.icons.dispatch;
            } else if (type === config.notificationType.DISPATCH_IN_TRANSIT) {
                icon = this.icons.inTransit;
            } else if (type === config.notificationType.DISPATCH_RECEIVED) {
                icon = this.icons.receive;
            } else if (type === config.notificationType.LOCKED_ACCOUNT) {
                icon = this.icons.locked;
            } else if (type === config.notificationType.UNLOCKED_ACCOUNT) {
                icon = this.icons.welcome;
            } else if (type === config.notificationType.CHANGE_LOCATION) {
                icon = this.icons.changeLocation;
            } else if (type === config.notificationType.FOR_APPROVAL_DISPOSAL_REQUEST
                || type === config.notificationType.APPROVED_DISPOSAL_REQUEST) {
                icon = this.icons.approve;
            } else if (type === config.notificationType.NBV_REVIEW_DISPOSAL_REQUEST) {
                icon = this.icons.nbvReview;
            } else if (type === config.notificationType.NEW_DISPOSAL_REQUEST
                || type === config.notificationType.NEW_REPAIR_REQUEST
                || type === config.notificationType.INCIDENT_REPORT_SUBMITTED) {
                icon = this.icons.document;
            } else if (type === config.notificationType.REJECTED_DISPOSAL_REQUEST) {
                icon = this.icons.reject;
            } else if (type === config.notificationType.ON_GOING_REPAIR_REQUEST) {
                icon = this.icons.onGoing;
            } else if (type === config.notificationType.DONE_REPAIR_REQUEST
                || type === config.notificationType.DONE_DISPOSAL_REQUEST) {
                icon = this.icons.done;
            } else {
                // Default mail icon
                icon = 'https://images.clipartlogo.com/files/istock/previews/7702/77021021-email-colored-vector-icon.jpg';
            }

            return icon;
        },
        async openDispatchDetails(notification) {
            const dispatchId = notification.meta ? notification.meta.dispatchId : null;

            if (dispatchId) {
                let dispatchObj = await dispatchDAO.getDispatchByDispatchId(dispatchId);
                EventBus.$emit('onShowDispatchDetails', dispatchObj);
            }
        },
        redirectDisposalRequest() {
            this.$router.push(this.disposalUrl);
        },
        redirectRepairRequest() {
            this.$router.push(this.repairUrl);
        }
    },
};
</script>

<style scoped>
.card {
    width: 376px;
    border: none;
    cursor: pointer;
}

.chat-header {
    padding: 4px 10px;
}

.chat-body {
    padding: 0px 10px 0px 10px;
}

.chat-footer {
    padding: 5px 10px;
}

.unread-color {
    background-color: #e7edf8;
}
</style>